import {DELETE, GET, POST, UPLOAD} from './ApiContract';


/******* SERVICE CATEGORY *********/
export const ServiceCategoryListAllService = (payload) => {
    return GET('clients/services/categories/all', payload);
}

export const ServiceCategoryListService = (payload) => {
    return GET('clients/services/categories/list', payload);
}

export const DeleteServiceCategory = (payload) => {
    return DELETE('clients/services/categories/delete', payload);
}

export const ServiceCategoryAdd = (payload) => {
    return UPLOAD('clients/services/categories/add', payload);
}

export const EditServiceCategory = (payload) => {
    return UPLOAD('clients/services/categories/edit', payload);
}

export const ServiceCategoryListParent = (payload) => {
    return GET('clients/services/categories/listparents', payload);
}

export const ServiceCategoryListChild = (scid, payload) => {
    console.log(payload)
    return GET('clients/services/categories/listchilds?categoryId=' + scid, payload);
}


/*************SERVICES*****************/
export const ServicesListService = (payload) => {
    return POST('clients/services/list', payload);

}
export const ServicesAdd = (payload) => {
    return UPLOAD('clients/services/add', payload);
}
export const ServicesEdit = (payload) => {
    return UPLOAD('clients/services/edit', payload);
}
export const GetServicesById = (payload) => {
    return GET('clients/services/get', payload);
}
export const ServicesDelete = (payload) => {
    return DELETE('clients/services/delete', null, payload);
}
export const ServicesDeleteSelected = (payload) => {
    return DELETE('clients/services/deleteselected', null, payload);
}
export const ServicesDeleteAll = (payload) => {
    return DELETE('clients/services/deleteall', null, payload);
}
export const InactiveServicesList = (payload) => {
    return POST('clients/services/inactivelist', payload);
}

export const PopularService = (payload) => {
    return POST('clients/services/toggle/popularitems', payload);
}
export const RecentlyService = (payload) => {
    return POST('clients/services/toggle/recentlyviewed', payload);
}
export const NewArrivalService = (payload) => {
    return POST('clients/services/toggle/newarrival', payload);
}
export const BundledOfferService = (payload) => {
    return POST('clients/services/toggle/bundledoffer', payload);
}

/**********FUEL TYPE SERVICES*******/
export const FuelTypeListService = (payload) => {
    return GET('clients/fueltype/list', payload);
}

export const AddFuelTypeService = (payload) => {
    return POST('clients/fueltype/add', payload);
}
/**********ASSET TYPE SERVICES*******/
export const AssetTypeListService = (payload) => {
    return GET('clients/assettype/list', payload);
}

export const AddAssetTypeService = (payload) => {
    return POST('clients/assettype/add', payload);
}
/*************ASSETS SERVICES*****************/
export const AssetListService = (payload) => {
    return POST('clients/assets/list', payload);
}
export const AssetsAdd = (payload) => {
    return POST('clients/assets/add', payload);
}
export const AssetsEdit = (payload) => {
    return POST('clients/assets/edit', payload);
}
export const GetAssetsById = (payload) => {
    return GET('clients/assets/get', payload);
}
export const AssetsDelete = (payload) => {
    return DELETE('clients/assets/delete', null, payload);
}
export const AssetsDeleteSelected = (payload) => {
    return DELETE('clients/assets/deleteselected', null, payload);
}
export const AssetsDeleteAll = (payload) => {
    return DELETE('clients/assets/deleteall', null, payload);
}
export const InactiveAssetsList = (payload) => {
    return POST('clients/assets/inactivelist', payload);
}
export const VerifyAssetService = (payload) => {
    return POST('clients/assetstatus/verify', payload);
}
export const UnVerifyAssetService = (payload) => {
    return POST('clients/assetstatus/unverify', payload);
}
export const AvailableAssetService = (payload) => {
    return POST('clients/assetstatus/available', payload);
}
export const UnAvailableAssetService = (payload) => {
    return POST('clients/assetstatus/notavailable', payload);
}
export const HeavydutyAssetService = (payload) => {
    return POST('clients/assetstatus/heavyduty', payload);
}
export const UnHeavydutyAssetService = (payload) => {
    return POST('clients/assetstatus/notheavyduty', payload);
}
export const VerifiedAssetsSummaryService = (payload) => {
    return POST('clients/assetsummary/verified', payload);
}
export const AssetSummaryListService = (payload) => {
    return POST('clients/assetsummary/list', payload);
}

/*************CLICKOUTS SERVICES*************/

export const ClickoutsListService = (payload) => {
    return POST('clients/clickouts/list', payload);
}
export const GetClickoutsById = (payload) => {
    return GET('clients/clickouts/get', payload);
}

/*************ACTIVE CONTACT SERVICES*****************/
export const ContactListService = (payload) => {
    return POST("clients/contactus/list", payload);
}
export const GetContactsById = (payload) => {
    return GET("clients/contactus/get", payload);
}
export const ContactDelete = (payload) => {
    return DELETE("clients/contactus/delete", null, payload);
}
export const ContactDeleteSelected = (payload) => {
    return DELETE("clients/contactus/deleteselected", null, payload);
}
export const ContactDeleteAll = (payload) => {
    return DELETE("clients/contactus/deleteall", null, payload);
}
export const InactiveContactsList = (payload) => {
    return POST("clients/contactus/inactivelist", payload);
}

/*************GETAQUOTE SERVICES*************/

export const GetaquoteListService = (payload) => {
    return POST('clients/getaquote/list', payload);
}
export const GetaQuoteById = (payload) => {
    return GET('clients/getaquote/get', payload);
}
/************** DATA FILES *****************/

export const ServicesDataFilesListUpload = (payload) => {
    return POST('clients/services/files/list', payload);
}
export const ServicesDataFileUpload = (payload) => {
    return UPLOAD('clients/services/files/upload', payload);
}
export const ServicesDownload = (payload) => {
    return GET('clients/services/files/download', payload);
}

export const TemplateDownload = (payload) => {
    return GET('clients/services/template/download', payload);
}

export const ActiveServices = (payload) => {
    return POST('clients/services/set/active', payload);
}

// export const SelectedDateService = (payload) => {
//     return POST('clients/clickouts/list', payload);
// }

import { DELETE, GET, POST, UPLOAD, PUT } from './ApiContract';

/************* SHIPMENT INFORMATION *****************/
export const ShipmentInfoAdd = (payload) => {
    return POST('clients/shipmentinformation/add', payload);
}
export const GetLocationTypes = (payload) => {
    return GET('clients/locationtype/getall', payload);
}
export const GetPickupAccesorials = (payload) => {
    return GET('clients/pickupaccesorials/getall', payload);
}
export const GetDeliveryAccesorials = (payload) => {
    return GET('clients/deliveryaccesorials/getall', payload);
}
export const GetShipmentInfoById = (payload) => {
    return GET('clients/shipmentinformation/get', payload);
}
export const ShipmentInfoEdit = (payload) => {
    return POST('clients/shipmentinformation/edit', payload);
}
/************* SHIPMENT PAYMENTS *****************/
export const ShipmentPaymentAdd = (payload) => {
    return POST('clients/shipmentpayments/add', payload);
}
export const GetPaymentTerms = (payload) => {
    return GET('clients/paymentterms/getall', payload);
}
export const GetPaymentMethod = (payload) => {
    return GET('clients/paymentmethod/getall', payload);
}
export const GetCurrency = (payload) => {
    return GET('clients/currency/getall', payload);
}
export const GetShipmentPayById = (payload) => {
    return GET('clients/shipmentpayments/get', payload);
}
export const ShipmentPayEdit = (payload) => {
    return POST('clients/shipmentpayments/edit', payload);
}

/************* SHIPMENT DETAILS *****************/
export const ShipmentDetailsAdd = (payload) => {
    return POST('clients/shipmentdetails/add', payload);
}
export const GetEquipment = (payload) => {
    return GET('clients/equipmenttype/getall', payload);
}
export const GetPackageType = (payload) => {
    return GET('clients/paymenttype/getall', payload);
}
export const GetShipmentDetailById = (payload) => {
    return GET('clients/shipmentdetails/get', payload);
}
export const ShipmentDetailEdit = (payload) => {
    return POST('clients/shipmentdetails/edit', payload);
}
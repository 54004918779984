import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import App from './App';
import {Provider} from "react-redux";
import ReduxToastr from 'react-redux-toastr'
import reportWebVitals from './reportWebVitals';
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css';
import process from 'process';
window.process = process;

ReactDOM.render(
    <React.StrictMode>
    <Provider store={store}>
        <Router>
            <Switch>
                <Route path="/" render={(routeProps) => {
                    return <App routeProps={routeProps}/>
                }}/>
            </Switch>
        </Router>
        <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            getState={(state) => state.toastr} // This is the default
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick/>
    </Provider>
   </React.StrictMode>,
   
    document.getElementById('root'));

reportWebVitals();

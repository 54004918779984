import {API_URL_BASE, APP_URL_BASE} from './../utilities/Constants';

const authHeader = () => {
    let authToken = localStorage.getItem('Authorization');

    if (authToken) {
        return {
            'Content-Type': 'application/json',
            'Authorization': authToken
        };
    } else {
        return {
            'Content-Type': 'application/json'
        };
    }
}
const mixedHeader = () => {
    let authToken = localStorage.getItem('Authorization');

    if (authToken) {
        return {
            'Content-Type': 'multipart/mixed',
            'Authorization': authToken
        };
    } else {
        return {
            'Content-Type': 'multipart/mixed'
        };
    }
}

const authHeaderMultipart = () => {
    let headers = authHeader();
    delete headers['Content-Type'];
    return headers
}

const authHeaderMixedMultipart = () => {
    let headers = mixedHeader();
    delete headers['Content-Type'];
    return headers
}

const objectToURLParams = (arg) => {
    arg = arg ? arg : [];
    let urlParams = Object.keys(arg).map((k) => {
        return encodeURIComponent(k) + '=' + encodeURIComponent(arg[k])
    }).join('&');

    if (urlParams) {
        urlParams = "?" + urlParams;
    }

    return urlParams;
}

const checkUnauthorized = (responseStatusCode, message) => {
    let code = Number(responseStatusCode);
    if (code === 403) {
        localStorage.clear();
        localStorage.setItem("errorMsg", message);
        window.location = APP_URL_BASE;
    }
}

export const POST = (url, payload, urlParams ={}) => {
    let params = objectToURLParams(urlParams);
    let urlPath = API_URL_BASE + url + params;
    return fetch(urlPath, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            if (responseData && responseData.hasOwnProperty('headers')) {
                console.log(url);
                checkUnauthorized(responseData.headers.statusCode, responseData.headers.message);
                if (responseData.hasOwnProperty('data')) {
                    return responseData;
                } else {
                    return false;
                }
            } else return false;
        })
        .catch(error => {
            console.warn(error);
            return {};
        });
}

export const PUT = (url, payload, urlParams) => {
    let params = objectToURLParams(urlParams);
    let urlPath = API_URL_BASE + url + params;
    return fetch(urlPath, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            if (responseData && responseData.hasOwnProperty('headers')) {
                console.log(url);
                checkUnauthorized(responseData.headers.statusCode, responseData.headers.message);
                if (responseData.hasOwnProperty('data')) {
                    return responseData;
                } else {
                    return false;
                }
            } else return false;
        })
        .catch(error => {
            console.warn(error);
            return {};
        });
}

export const GET = (url, payload) => {
    let params = objectToURLParams(payload);
    let urlPath = API_URL_BASE + url + params;
    return fetch(urlPath, {
        method: "GET",
        headers: authHeader(),
    })
        .then((response) => response.json())
        .then((responseData) => {
            if (responseData && responseData.hasOwnProperty('headers')) {
                console.log(url);
                checkUnauthorized(responseData.headers.statusCode, responseData.headers.message);
                if (responseData.hasOwnProperty('data')) {
                    return responseData;
                } else {
                    return false;
                }
            } else return false;
        })
        .catch(error => {
            console.warn(error);
            return {};
        });
}

// export const DELETE = (url, urlParams, payload) => {
//     let params = objectToURLParams(urlParams);
//     let urlPath = API_URL_BASE + url + params;
//     return fetch(urlPath, {
//         method: "DELETE",
//         body: JSON.stringify(payload),
//         headers: authHeader()
//     })
//         .then((response) => response.json())
//         .then((responseData) => {
//             if (responseData && responseData.hasOwnProperty('headers')) {
//                 console.log(url);
//                 checkUnauthorized(responseData.headers.statusCode, responseData.headers.message);
//                 if (responseData.hasOwnProperty('data')) {
//                     return responseData;
//                 } else {
//                     return false;
//                 }
//             } else return false;
//         })
//         .catch(error => {
//             console.warn(error);
//             return {};
//         });
// }
export const DELETE = (url, urlParams, payload) => {
    let params = objectToURLParams(urlParams);
    let urlPath = API_URL_BASE + url + params;
    return fetch(urlPath, {
        method: "DELETE",
        body: JSON.stringify(payload),
        headers: authHeader()
    })
        .then((response) => response.json())
        .then((responseData) => {
            if (responseData && responseData.hasOwnProperty('headers')) {
                checkUnauthorized(responseData.headers.statusCode, responseData.headers.message);
                if (responseData.hasOwnProperty('data')) {
                    return responseData;
                } else {
                    return false;
                }
            } else return false;
        })
        .catch(error => {
            console.warn(error);
            return {};
        });
};


export const UPLOAD = (url, payload) => {
    let urlPath = API_URL_BASE + url;
    return fetch(urlPath, {
        method: "POST",
        body: payload,
        headers: authHeaderMultipart()
    })
        .then((response) => response.json())
        .then((responseData) => {
            if (responseData && responseData.hasOwnProperty('headers')) {
                console.log(url);
                checkUnauthorized(responseData.headers.statusCode, responseData.headers.message);
                if (responseData.hasOwnProperty('data')) {
                    return responseData;
                } else {
                    return false;
                }
            } else return false;
        })
        .catch(error => {
            console.warn(error);
            return {};
        });
}


// export const UPLOAD = (url, payload) => {
//     let urlPath = API_URL_BASE + url;
//     return fetch(urlPath, {
//         method: "POST",
//         body: payload,
//         headers: authHeaderMultipart()
//     })
//         .then((response) => response.json()) // Ensure `.then()` is attached correctly
//         .then((responseData) => {
//             if (responseData && responseData.headers) { // Safely check if `headers` exists
//                 console.log(url);
//                 checkUnauthorized(responseData.headers.statusCode, responseData.headers.message);
//                 if (responseData.hasOwnProperty('data')) {
//                     return responseData;
//                 } else {
//                     return false;
//                 }
//             } else {
//                 return false;
//             }
//         })
//         .catch((error) => {
//             console.warn(error);
//             return {};
//         });
// };




export const MIXED = (url, payload) => {
    let urlPath = API_URL_BASE + url;
    return fetch(urlPath, {
        method: "POST",
        body: payload,
        headers: authHeaderMixedMultipart()
    })
        .then((response) => response.json())
        .then((responseData) => {
            if (responseData && responseData.hasOwnProperty('headers')) {
                console.log(url);
                checkUnauthorized(responseData.headers.statusCode, responseData.headers.message);
                if (responseData.hasOwnProperty('data')) {
                    return responseData;
                } else {
                    return false;
                }
            } else return false;
        })
        .catch(error => {
            console.warn(error);
            return {};
        });
}


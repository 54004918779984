import {DELETE, GET, POST, UPLOAD} from './ApiContract';

export const UserTypesService = (payload) => {
    return GET('users/type/trmlist', payload);
}
export const TrmTypesService = (payload) => {
    return GET('users/type/trmlist', payload);
}
export const UserRolesService = (payload) => {
    return GET('users/roles/list', payload);
}
export const FilterMetadataService = (payload) => {
    return GET('users/metadata/get', payload);
}

export const FilterAddService = (payload) => {
    return POST('users/filters/add', payload);
}

export const FilterListService = (payload) => {
    return POST('users/filters/list', payload);
}

export const FilterDeleteService = (payload) => {
    return DELETE('users/filters/delete', payload);
}

export const AppSettingsService = (payload) => {
    return GET('users/settings/web', payload);
}

export const ApplicationSettingService = (payload) => {
    return GET('users/settings/list', payload);
}

export const GetClientCategoriesService = (payload) => {
    return GET('clients/categories/list', payload);
}

export const AddClientCategoryService = (payload) => {
    return POST('clients/categories/add', payload);
}

export const TemplateFilesService = (payload) => {
    return POST('clients/document/templates/list', payload);
}

export const TemplateFileUploadService = (payload) => {
    return UPLOAD('clients/document/templates/upload', payload);
}

export const GetDocumentTypesService = (payload) => {
    return POST('clients/document/types/list', payload);
}

export const AddDocumentTypesService = (payload) => {
    return POST('clients/document/types/add', payload);
}

export const DocumentCategoryListService = (payload) => {
    return GET('clients/categories/list', payload);
}

export const DocumentTemplateListService = (payload) => {
    return GET('clients/document/templates/getall', payload);
}

export const ClientCategoryDeleteService = (payload) => {
    return DELETE('clients/categories/delete', payload);
}

export const TemplateDeleteService = (payload) => {
    return DELETE('clients/document/templates/delete', payload);
}

export const TemplateDownloadService = (payload) => {
    console.log(payload);
    return GET('clients/document/templates/download', payload);
}

export const TypeDeleteService = (payload) => {
    return DELETE('clients/document/types/delete', payload);
}

export const EditTemplateService = (payload) => {
    return UPLOAD('clients/document/templates/edit', payload);
}

export const EditClientCategoryService = (payload) => {
    return POST('clients/categories/edit', payload);
}

export const EditTypeService = (payload) => {
    return POST('clients/document/types/edit', payload);
}
/**********DASHBOARD SERVICE*******/
export const GetDashboardByIdService = (payload) => {
    return GET('common/dashboard/trmadmin/get', payload);
}
/*******************ACTIVITY LOGS************************* */
export const TrmActionsService = (payload) => {
    return GET('common/activity/trmactions', payload);
}
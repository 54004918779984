import {DELETE, GET, POST, UPLOAD, PUT} from './ApiContract';

/************* LOADS *****************/
export const LoadsListService = (payload) => {
    return POST('clients/loads/list', payload);
}
export const AssignLoadCarrier = (payload) => {
    return POST('clients/loadprocess/assign', payload);

}
export const AssignLoadTruckCarrier = (payload) => {
    return POST('clients/loadprocess/carrier/assigntruck', payload);

}
export const DownloadPodService = (payload) => {
    return GET('clients/loadprocess/pod/download', payload);
}

export const DeliveredUploadService = (payload) => {
    return UPLOAD('clients/loadprocess/deliver', payload);

}
export const DeleteLoadService = (payload) => {
    return DELETE('clients/loaddata/delete', null, payload);

}
export const DispatchLoadService = (payload) => {
    return POST('clients/loadprocess/dispatch', payload);

}


export const InactiveLoadList = (payload) => {
    return POST('clients/loads/inactivelist', payload);
}
export const LoadsAdd = (payload) => {
    return POST('clients/loaddata/add', payload);
}
export const LoadsEdit = (payload) => {
    return POST('clients/loaddata/edit', payload);
}
export const GetLoadsById = (payload) => {
    return GET('clients/loads/get', payload);
}
export const CarrierCategory = (payload) => {
    return GET('clients/carriers/all', payload);
}
export const ShipperCategory = (payload) => {
    return GET('clients/shippers/all', payload);
}
export const AssetCategory = (carrierId, payload) => {
    return GET('clients/assets/all?carrierId=' + carrierId, payload);
}

export const SearchLoadsServices = (payload) => {
    return POST('clients/loadboard/admin/searchloads', payload);
}

export const PostTruckService = (payload) => {
    return POST('clients/lanes/posttruck', payload);
}

export const TextOrginService = (origin, payload) => {
    return GET('common/geocities/search?text=' + origin, payload);
}

export const AssignLoadBoardCarrier = (payload) => {
    return POST('clients/loadboard/assign', payload);

}
export const consigneeZipService = (zip, payload) => {
    return GET('common/geocities/getzip?text=' + zip, payload);
}

/************ LANE SERVICES *************/

export const LanesListService = (payload) => {
    return POST('clients/lanes/admin/list', payload);
}
export const LanesAdd = (payload) => {
    return POST('clients/lanes/admin/add', payload);
}
export const LanesEdit = (payload) => {
    return POST('clients/lanes/admin/edit', payload);
}
export const GetLanesById = (payload) => {
    return GET('clients/lanes/get', payload);
}
export const LanesEnable = (payload) => {
    return PUT('clients/lanes/setactive?laneId=' + payload.laneId, payload);
}

export const LanesDisable = (payload) => {
    return PUT('clients/lanes/setinactive?laneId=' + payload.laneId, payload);
}
// -------------------------LOAD BOARD--------------------------
export const TextXOrginService = (origin, payload) => {
    return GET('common/geocities/xsearch?text=' + origin, payload);
}
export const LoadboardAdd = (payload) => {
    return POST('clients/loadboard/admin/postload/add', payload);
}
export const LoadBoardEdit = (payload) => {
    return POST('clients/loadboard/admin/postload/edit', payload);
}
export const DeleteLoadboardService = (payload) => {
    return PUT('clients/loadboard/postload/delete', null, payload);
}
export const GetLoadbordId = (payload) => {
    return GET('clients/loadboard/postload/get', payload);
}
export const SearchTruckLoad = (payload) => {
    return POST('clients/loadboard/searchtrucks', payload);
}
export const GetLoadbordList = (payload) => {
    return GET('clients/loadboard/admin/postload/list', payload);
}
export const GetCarrierByIdService = (payload) => {
    return GET('clients/carriers/get', payload);
}

export const AssetLengthService = (cid1, payload) => {
    return GET('clients/assettypelength/get?assetTypeId=' + cid1, payload);
}
export const AssetTypeListService = (payload) => {
    return GET('clients/assettype/list', payload);
}
export const TextXSearchService = (payload) => {
    return POST('common/search/text', payload);
}

export const FetchXDetailsService = (payload) => {
    return GET('common/fetch/data', payload);
}
